
import { createContext, useEffect } from "react";


const AuthContext = createContext();

const AuthProvider = ({ children }) => { 

    /** submitCredentials(credentials)
     * @abstract Function to submit the login credentials to the API endpoint 
     * @param { Object } credentials 
     * @returns { Object } Returns the content of the response (JSON)
     */
    async function submitCredentials(credentials) {
        console.log('Submitting credentials: ', credentials);

        return fetch('http://localhost:8008/login', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
        .then(data => data.json());
    }

    
    /** login(username, password, errorFunc)
     * @abstract Function that uses submitCredentials() to send the given username and password to the API and handles the
     * response; either redirects to the dashboard page or sets the on-screen error.
     * @param { String } username 
     * @param { String } password 
     * @param { function } errorFunc
     * @returns { null }
     */
    async function login(username, password, errorFunc) { 
        console.log('Submitting login(): ', username, password);

        // Hit login API endpoint
        const response = await submitCredentials({
            username,
            password
        });

        console.log('login got response: ', response);

        if(response.status == 200) {
            window.location.href = '/dashboard';
        } else { 
            errorFunc();
        }
    }


    // Value provided by the context
    const authContextValue = {
        login
    };

    return (
        <AuthContext.Provider value={authContextValue}>
          {children}
        </AuthContext.Provider>
      );
}

export { 
    AuthProvider, 
    AuthContext
};