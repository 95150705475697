
import React, { useState, useEffect } from "react";

import { formatPhoneNumber } from "../utils/general";


function PhoneNumberInput() {
    const [phone, setPhone] = useState("");

    /**
         * @const handleChange
         * @description Event handler for when the phone number input value changes
         * @param { Event } e 
         */
    const handleChange = (e) => {
        setPhone(e.target.value);
    };

    // useEffect() => reformats the phone number as the user types
    useEffect(() => {
        const formatted = formatPhoneNumber(phone);
        if (formatted !== phone) {
            setPhone(formatted);
        }
    }, [phone]);

    return (
        <div>
            <input
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={handleChange}
            placeholder="(000) 000-0000"
            />
        </div>
    );
}

export default PhoneNumberInput;
