import { useState, useEffect } from 'react';
import { apiGET } from '../utils/api';

 
const SiteTitle = () => {
    const [siteTitle, setSiteTitle] = useState("Josie's Bakery");

    // useEffect => sets the site title on page load.
    useEffect(() => {
        async function fetchSiteTitle() {
            const title = await apiGET('/get-title');
            setSiteTitle(title.title);
        };

        fetchSiteTitle();
    }, []);

    return(
        <div className='site-title'>{siteTitle}</div>
    )
}

export default SiteTitle;