
import { useState, useEffect } from 'react';
import { apiGET } from '../utils/api';

import '../css/ContactInfoBox.css';


const ContactInfoBox = () => {
    const [contactInfo, setContactInfo] = useState({'phone': '', 'email': '', 'address': ''});

    useEffect(() => {
        async function fetchContactInfo() { 
            const info = await apiGET('/get-contact-info');
            setContactInfo(info);
        }
        
        fetchContactInfo();
    }, []);

    return (
        <div className='contact-info-box'>
            <h1>Contact Information</h1>
            <div className='contact-info-row'>
                <span>Phone</span>
                <p>{contactInfo.phone}</p>
            </div>
            <div className='contact-info-row'>
                <span>Email</span>
                <p>{contactInfo.email}</p>
            </div>
            <div className='contact-info-row'>
                <span>Address</span>
                <p>{contactInfo.address}</p>
            </div>
        </div>
    );
}


export default ContactInfoBox;