import { apiBaseUrl } from "../config";


/**
 * @function apiGET
 * @async 
 * @description Submits a GET request to the API to the given endpoint and with the given params
 * @param { String } endpoint the endpoint to hit
 * @param { Object } params params for the GET req as a dict
 * @returns { Object } the response's JSON body, or empty dict if an error occurs
 */
export async function apiGET(endpoint, params) { 

    try {
        // Format the URL and make the API request
        const response = await fetch(`${apiBaseUrl}${endpoint}?${new URLSearchParams(params).toString()}`, {
            credentials: 'include',
        });

        // Await the JSON response
        const responseJson = await response.json();

        // Check status and return appropriately
        if(response.ok) return responseJson;
        else throw new Error();

    // If any errors, console log and return empty dict
    } catch (error) { 
        console.log('ERROR on apiGET(): ', error);
        console.log(`Given endpoint "${endpoint}"`)
        console.log('Given params: ', params);
        return {};
    }
}
    

export async function apiPOST(endpoint, data) {
    try {
        const options = {
            method: "POST",
            body: data instanceof FormData ? data : JSON.stringify(data),
            credentials: 'include'
        };

        // Only add headers for JSON payloads
        if (!(data instanceof FormData)) {
            options.headers = { "Content-Type": "application/json" };
        }

        const response = await fetch(`${apiBaseUrl}${endpoint}`, options);

        // Await the JSON response
        const responseJson = await response.json();

        // Check status and return appropriately
        if(response.ok) return responseJson;
        else throw new Error();
        
    } catch (error) {
        console.log('ERROR on apiPOST(): ', error);
        console.log(`Given endpoint "${endpoint}"`)
        console.log('Given data: ', data);
        return {};
    }
}


/**
 * @function getMenuItems()
 * @description Gets the menu items from the API.
 * @returns { Object } The response's JSON as a dict (object).
 */
export async function getMenuItems() { 
    const responseJson = await apiGET('/get-items', {});
    return responseJson;
}