import React from 'react';
import '../css/Spinner.css'; 

const Spinner = () => {
    return (
    <div className="spinner"></div>
    );
};

export default Spinner;
