
import { useState, useEffect } from 'react';

import PortalHeader from '../components/PortalHeader.js';

import { apiGET, apiPOST } from '../utils/api';
import { isEmpty, reformatDate } from '../utils/general.js';
import PhoneNumberInput from '../components/PhoneNumberInput.js';

import '../css/PortalHome.css';


function PortalHome() { 
    const [orderData, setOrderData] = useState([]);
    const [orderItemsData, setOrderItemsData] = useState({'0': {}});


    const submitOrdersSearch = async (e) => {
        e.preventDefault();

        // Construct a formData obj
        const formData = new FormData();

        formData.append('orderId', e.target['order-id'].value);
        formData.append('firstName', e.target['first-name'].value);
        formData.append('lastName', e.target['last-name'].value);
        formData.append('phone', e.target['phone'].value);
        formData.append('email', e.target['email'].value);
        formData.append('orderDate', e.target['order-date'].value);
        formData.append('orderTime', e.target['order-time'].value);

        // Submit the search to the API
        const responseJson = await apiPOST('/search-orders', formData);
        
        // Handle the response
        if(responseJson.status != 200) {
            console.log('ERROR on search submit:', responseJson.msg);
            alert('An error occured while searching orders.');
        } else {
            console.log(responseJson.data);
            setOrderData(responseJson.data.customer_orders);

            // Reduce the order items data and set it on the page
            const reducedOrderItems = responseJson.data.order_items.reduce((acc, item) => {
                const { order_id, ...rest } = item; // Extract the ID and remaining attributes
                if (!acc[order_id]) {
                    acc[order_id] = []; // Initialize the array if the ID doesn't exist
                }
                acc[order_id].push(rest); // Add the remaining attributes to the list for this ID
                return acc;
                }, {});

            setOrderItemsData(reducedOrderItems);
        }
    }


    // Fetch all required data on page load
    // NOTE: redirects to login page if user is not signed in/if API returns 404 unauth 
    useEffect(() => {
        async function fetchOrderData() {
            try { 
                // Fetch the data from the API
                const responseJson = await apiGET('/get-orders-data');
        
                // Check response and either set the order data or throw an error to redirect
                if(isEmpty(responseJson) || responseJson.status == 400) throw new Error('UNAUTHORIZED');
                
                setOrderData(responseJson.data.customer_orders);
                
                // Reduce the order items data and set it on the page
                const reducedOrderItems = responseJson.data.order_items.reduce((acc, item) => {
                    const { order_id, ...rest } = item; // Extract the ID and remaining attributes
                    if (!acc[order_id]) {
                      acc[order_id] = []; // Initialize the array if the ID doesn't exist
                    }
                    acc[order_id].push(rest); // Add the remaining attributes to the list for this ID
                    return acc;
                  }, {});
                setOrderItemsData(reducedOrderItems);

            } catch (e) { 
                console.error('Error on fetchOrderData:', e);
                window.location.href = '/adm-login';
            }
        }
        fetchOrderData();

    }, []);


    return (
        <div className='portal-home-page'>
            <PortalHeader selected='orders' />

            <div className='orders-search-container'>
                <h1 className='portal-home-subheader'>Search Orders</h1>
                <form className='orders-search-bar' onSubmit={submitOrdersSearch}>
                    <input type='number' name='order-id' placeholder='Order ID'></input>
                    <input type='text' name='first-name' placeholder='First name'></input>
                    <input type='text' name='last-name' placeholder='Last name'></input>
                    <PhoneNumberInput />
                    <input type='email' name='email' placeholder='example@gmail.com'></input>
                    <input type='date' name='order-date'></input>
                    <input type='time' name='order-time'></input>
                    <button type='submit'>Search</button>
                </form>
            </div>
            
            
            <div className='orders-list'> 
                {
                    orderData.map(order => (
                        <div className='order-div' id={`order-div-${order.order_id}`}>
                            <h1>{order.customer_first_name} {order.customer_last_name}</h1>
                            <h2><span>Order ID:</span> {order.order_id}</h2>
                            <h2><span>Order Date:</span> {reformatDate(order.order_date)}</h2>
                            <h2><span>Order Time:</span> {order.order_time}</h2>
                            <h2><span>Order Total:</span> ${order.order_total}</h2>
                            <div className='order-details-container'>
                                <div className='customer-info-box'>
                                    <h3>Customer Information</h3>
                                    <div className='order-details-row'>
                                        <label>Customer Phone</label>
                                        <p>{order.customer_phone}</p>
                                    </div>
                                    <div className='order-details-row'>
                                        <label>Customer Email</label>
                                        <p>{order.customer_email}</p>
                                    </div>
                                </div>
                                
                                <div className='payment-info-box'>
                                    <h3>Payment Information</h3>
                                    <div className='order-details-row'>
                                        <label>CC Number</label>
                                        <p>{order.cc_number}</p>
                                    </div>
                                    <div className='order-details-row'>
                                        <label>CC Expiry</label>
                                        <p>{order.cc_expiry}</p>
                                    </div>
                                    <div className='order-details-row'>
                                        <label>CC CVV</label>
                                        <p>{order.cc_cvv}</p>
                                    </div>
                                </div>

                                <div className='order-items-box'>
                                    <h3>Order Items</h3>
                                    <table>
                                        <thead>
                                            <th>Item Name</th>
                                            <th>Quantity</th>
                                        </thead>
                                        <tbody>
                                            {
                                                orderItemsData[order.order_id]?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{item.item_name}</td>
                                                        <td>{item.quantity}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
};


export default PortalHome;
