
import { useState, useEffect } from 'react';

import SiteTitle from "../components/SiteTitle";
import ContactInfoBox from '../components/ContactInfoBox';
import FormHeader from "../components/FormHeader";
import Footer from '../components/Footer';

import { apiGET } from '../utils/api';

import '../css/ConfirmationPage.css';

function ConfirmationPage () {
    const [confirmationMessage, setConfirmationMessage] = useState('Order Received!');


    // useEffect => fetches the confirmation message from settings on page load
    useEffect(() => {
        async function fetchConfirmationMessage() {
            const msg = await apiGET('/get-confirmation-msg');
            setConfirmationMessage(msg.message);
        }

        fetchConfirmationMessage();
    }, []);


    return(
        <div className='confirmation-page'>
            <FormHeader />
            <SiteTitle />

            <div className='confirmation-page-inner'>
                <div className='confirmation-msg'>{confirmationMessage}</div>
                <div className='thank-you-msg'>
                    Thank you for choosing Josie's Bakery!
                </div>
            </div>
            
            <Footer />
        </div>
    );
}


export default ConfirmationPage;