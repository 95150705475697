import React from 'react';
import { useState, useEffect } from 'react';

import { apiGET, apiPOST, getMenuItems } from '../utils/api';
import { 
    getCurrentDate, 
    parseTime, 
    isValidEmail, 
    isValidPhoneNumber, 
    isValidCreditCardNumber,
    isValidExpiryDate,
    isValidCVV,
    formatPhoneNumber,
    convertTo24Hour
} from '../utils/general';

import SiteTitle from '../components/SiteTitle';
import FormHeader from '../components/FormHeader';
import Footer from '../components/Footer';
import PhoneNumberInput from '../components/PhoneNumberInput';

import '../css/FormPage.css';

function FormPage() {
    const [formPolicy, setFormPolicy] = useState('');
    const [allowedDates, setAllowedDates] = useState([{'date': getCurrentDate(), 'times': []}]);

    const [minDate, setMinDate] = useState({'date': getCurrentDate(), 'times': []});
    const [maxDate, setMaxDate] = useState({'date': getCurrentDate(), 'times': []});

    const [selectedDate, setSelectedDate] = useState({'date': getCurrentDate(), 'times': []});
    const [selectedTime, setSelectedTime] = useState('09:00 AM');

    const [orderItems, setOrderItems] = useState({});
    const [customerTotal, setCustomerTotal] = useState(0);
    const [menuItems, setMenuItems] = useState({});


    /**
     * @function handleFormSubmit
     * @description Handles form submission, creating a FormData object and sending it to the API.
     * @param {Event} e - The form submit event.
     */
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Perform client-side validation for specific fields 
        // NOTE: split up the checks so we can give the user more context on which fields to check

        // Checking email and phone number
        if(
            (!isValidEmail(e.target['email'].value)) || 
            (!isValidPhoneNumber(e.target['phone'].value))
        ) {
            alert('Some fields contain invalid values. Check the email and phone number fields.');
            return;
        } 
        
        // Checking payment info
        if(
            (!isValidCreditCardNumber(e.target['cc-number'].value)) || 
            (!isValidExpiryDate(e.target['cc-expiry'].value)) || 
            (!isValidCVV(e.target['cc-cvv'].value))
        ) {
            alert('Some fields contain invalid values. Check the payment info fields.');
            return;
        } 

        // Continue if we pass prelim checks
        const formData = new FormData();

        // Add customer information
        formData.append('firstName', e.target['first-name'].value);
        formData.append('lastName', e.target['last-name'].value);
        formData.append('phone', formatPhoneNumber(e.target['phone'].value));
        formData.append('email', e.target['email'].value);

        // Add order date and time
        formData.append('orderDate', selectedDate.date);
        formData.append('orderTime', selectedTime);

        // Add order items
        Object.entries(orderItems).forEach(([itemID, quantity]) => {
            formData.append(`items[${parseInt(itemID) + 1}]`, quantity);
        });

        // Add total
        formData.append('total', customerTotal);

        // Add payment information
        formData.append('ccNumber', e.target['cc-number'].value);
        formData.append('ccExpiry', e.target['cc-expiry'].value);
        formData.append('ccCvv', e.target['cc-cvv'].value);

        // Add policy agreement
        formData.append('policyAgreement', e.target['policy-agreement'].checked);

        // Submit the form data to the API
        try {
            const responseJSON = await apiPOST(
                '/submit-inquiry', 
                formData,
            );

            // Verify the result
            if(responseJSON.status != 200) {
                alert(responseJSON.error);
                return;
            }
            
            // Redirect the user to the confirmation screen 
            window.location.href = '/submitted';

        } catch (error) {
            console.error('Error submitting the form:', error);
            alert(error);
        }

       
    };


    /**
     * @const handleDateChange 
     * @description Event handler for when the selected date is changed
     * @param { Event } e 
     */
    const handleDateChange = (e) => { 
        const newDate = allowedDates.find((dateObj) => dateObj.date === e.target.value)
        setSelectedDate(newDate);

        // Set the selected time to the earliest allowed for this date
        setSelectedTime(newDate.times[0]);
    }


    /**
     * @const handleTimeChange
     * @description Event handler for when a new time is selected
     * @param { Event } e 
     */
    const handleTimeChange = (e) => {
        e.preventDefault();
        setSelectedTime(e.target.value);
    }


    /**
     * @constant updateOrderItems
     * @description Event handler for updating the order items when an item is selected/updated.
     * @param { Event } e 
     */
    const updateOrderItems = (e) => { 

        // Extract the item ID and new quantity from the event 
        const updateItemID = e.target.name;
        const newItemQuantity = parseInt(e.target.value);
       
        console.log("Updating Item:", updateItemID, "Quantity:", newItemQuantity);

        // Add/update the items in the orderItems state var
        setOrderItems((prevDict) => ({
            ...prevDict,
            [updateItemID - 1]: newItemQuantity         // NOTE: using updateItemID - 1 b/c of indexing at 0 vs. item IDs start at 1
        }));
    }


    //useEffect() => gets the form config on page load
    useEffect(() => { 
        async function fetchFormConfig() { 

            // Make the API request
            const responseJson = await apiGET('/get-form-config', {});

            // Parse the allowed dates and sort the times into ascending order
            responseJson.allowedDates.forEach(dateProperties => {
                dateProperties.times = dateProperties.times.sort((a, b) => {
                    // Parse time strings into Date objects
                    const dateA = new Date(`1970-01-01T${convertTo24Hour(a)}:00`);
                    const dateB = new Date(`1970-01-01T${convertTo24Hour(b)}:00`);
                
                    // Compare the Date objects
                    return dateA - dateB;
                });
            });
            // Set the required elements on the page
            setFormPolicy(responseJson.formPolicy);
            setAllowedDates(responseJson.allowedDates);
        }

        // Call async func fetchFormConfig
        fetchFormConfig();

        // Fetch the menu items from the API
        const fetchMenuItems = async () => {
            const fetchedMenuItems = await getMenuItems();
            setMenuItems(fetchedMenuItems);
        };

        // Call fetchMenuItems ONLY if the dict is empty
        if(Object.keys(menuItems).length === 0) { 
            fetchMenuItems();
        }

        // Init all the menu items as 0 on the form
        Object.keys(menuItems).forEach(elm => { 
            if(elm.item_id) {
                try {
                    document.getElementById(elm.item_id).value = 0;
                } catch {
                    console.log(`ERROR FINDING ELEMENT ID "${elm.item_id}"`)
                }
            }  
        });

    }, []);

    // useEffect() => updates the customerTotal whenever the orderItems dict is altered.
    useEffect(() => {

        // Init the new total as 0
        var newTotal = 0;

        // Iterate over each of the orderItems and add the price for that item * quantity to
        // the newTotal 
        Object.entries(orderItems).forEach(([itemID, quantity]) => {            
            const itemPrice = menuItems[itemID].item_price;            
            newTotal += itemPrice * quantity
        });

        // Reset the customer total with the new updated total
        setCustomerTotal(newTotal);

    }, [orderItems]);
   
    // useEffect() => updates the min and max dates when allowedDates is updated
    useEffect(() => {

        // Set the min date in the date picker AND set the currently selected date to the min date
        const newMinDate = allowedDates.reduce((min, current) =>
            new Date(current.date) < new Date(min.date) ? current : min
        );

        setMinDate(newMinDate);
        setSelectedDate(newMinDate);

        // Set the max date in the date picker
        setMaxDate(
            allowedDates.reduce((max, current) =>
                new Date(current.date) > new Date(max.date) ? current : max
            )
        );
    }, [allowedDates]);

    // useEffect() => updates the min and max times in the display when a new date is selected, and clears the currently selected time
    useEffect(() => {

        // Filter to get the min and max time for this date
        if(selectedDate.times.length > 0) {
            document.getElementById('order-time').disabled = false;
        } else { 
            document.getElementById('order-time').disabled = true;
        }
    }, [selectedDate]);


    return (
        <div className='form-page'>
            <FormHeader />
            <SiteTitle />

            <form onSubmit={handleFormSubmit}>

                {/* First input section for general order info */}
                <div className='input-section' id='order-info-section'>
                    <div className='section-header'>Order Information</div>

                    <label style={{textAlign: 'center', marginBottom: '3vh'}}>Customer Information</label>
                    <div className='input-row customer-info-input-row'>
                        <input type='text' name='first-name' placeholder='First Name' />
                        <input type='text' name='last-name' placeholder='Last Name' />
                        <PhoneNumberInput />
                        <input type='email' name='email' placeholder='example@gmail.com' />
                    </div>

                    <div className='input-row'>
                        <label htmlFor='order-date'>Order Date & Time</label>
                    </div>

                    <div className='input-row date-time-input-row'>  
                        <input
                            type='date' 
                            className='custom-date-input'
                            id='order-date'
                            name='order-date' 
                            value={selectedDate.date}
                            onChange={handleDateChange}
                            min={minDate.date}
                            max={maxDate.date} 
                        />

                        <select
                            className='custom-time-picker'
                            id='order-time'
                            name='order-time'
                            value={selectedTime} // Make sure the value reflects the current state
                            onChange={handleTimeChange} // Use onChange here
                        >
                            {
                                selectedDate.times.length > 0 ? (
                                    selectedDate.times.map((time) => (
                                        <option
                                            className='time-option'
                                            value={time}
                                            key={`time-option-${time}`}
                                        >
                                            {time}
                                        </option>
                                    ))
                                ) : (
                                    <option className='time-option' value=''>
                                        No times available.
                                    </option>
                                )
                            }
                        </select>
                    </div>

                </div>

                {/* Second input section for order items */}
                <div className='input-section' id='order-items-section'>
                    <h2 className='section-header'>Menu Items</h2>
                    { 
                        Object.entries(menuItems).map(([idx, itemProperties]) => (
                            <div className='menu-item-row' key={`menu-item-row-${itemProperties.item_id}`}> 
                                <input 
                                    type='number' 
                                    className='menu-item-input' 
                                    id={itemProperties.item_id}
                                    name={itemProperties.item_id} 
                                    min='0'
                                    onChange={updateOrderItems}
                                /> 

                                <div htmlFor={itemProperties.item_id} className='menu-item-label' key={`label-${itemProperties.item_id}`}>
                                    <span className='item-label'>{itemProperties.item_name}</span>
                                    <span className='item-price-label'><strong>${itemProperties.item_price}</strong></span>
                                </div>
                            </div>  
                        ))
                    }
                </div>

                {/* Final section for confirmation, etc */}
                <div className='input-section' id='confirmation-section'>
                    <h2 className='section-header'>Confirmation</h2>
                    
                    {/* Confirmation table */}
                    <label>Order Summary:</label>
                    <table className='confirmation-table'>
                        <thead>
                            <th>Item</th>
                            <th>Price (1)</th>
                            <th>Quantity</th>
                            <th>Price (1) x Quantity</th>
                        </thead>
                        <tbody>
                            {
                                Object.entries(orderItems).map(([itemID,quantity]) => (
                                    quantity > 0 ? 
                                        <tr key={[itemID]}>
                                            <td>{menuItems[itemID].item_name}</td>
                                            <td style={{textAlign: 'center'}}>$ {menuItems[itemID].item_price}</td>
                                            <td style={{textAlign: 'center'}}>{quantity}</td>
                                            <td style={{textAlign: 'center'}}>$ {menuItems[itemID].item_price * quantity}</td>
                                        </tr>
                                    : 
                                        ''
                                ))
                            }
                            <tr>
                                <td style={{borderRight: 'none'}}><strong>My total</strong></td>
                                <td style={{borderLeft: 'none', borderRight: 'none'}}></td>
                                <td style={{borderLeft: 'none', borderRight: 'none'}}></td>
                                <td style={{textAlign: 'center', borderLeft: 'none'}}><strong>$ {customerTotal}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                    
                    {/* Payment info */}
                    <div className='payment-info-row'>
                        <label>Payment Info</label>
                        <input type='text' className='payment-info-input' name='cc-number' id='cc-number-input' placeholder='XXXX XXXX XXXX 1234' style={{width: '200px'}} />
                        <input type='text' className='payment-info-input' name='cc-expiry' id='cc-expiry-input' placeholder='mm/yy' style={{width: '75px'}}/>
                        <input type='text' className='payment-info-input' name='cc-cvv' id='cc-cvv-input' placeholder='cvc' style={{width: '75px'}}/>
                    </div>

                    {/* Policy */}
                    <label>Policy Agreement</label>
                    <div className='policy-box'>
                        <p className='policy-content'>{formPolicy}</p>
                    </div>

                    <div className='policy-agreement-row'>
                        <input type='checkbox' name='policy-agreement' id='policy-agreement-checkbox' required></input>
                        <p className='policy-agreement-text'>I agree to the policy.</p>
                    </div>

                    {/* Submit button */}
                    <button type='submit' className='submit-button'>Submit</button>

                </div>


            </form>

            {/* Footer */}
            <Footer />
        </div>
    )
}


export default FormPage;