// src/App.js
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react'; 

import FormPage from './pages/FormPage';
import ConfirmationPage from './pages/ConfirmationPage';
import LoginPage from './pages/LoginPage';
import PortalHome from './pages/PortalHome';
import PortalMenuPage from './pages/PortalMenuPage';

function App() {

  return (
    <BrowserRouter>
      <Routes>

        <Route exact path="/" element={<FormPage />} /> 
        <Route exact path='/submitted' element={<ConfirmationPage />} />
        <Route exact path='/adm-login' element={<LoginPage />} />
        
        <Route exact path='/portal' element={<PortalHome />} />
        <Route exact path='/portal/menu' element={<PortalMenuPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
