import '../css/FormPageHeader.css'

const FormHeader = () => {

    return (
        <div className='form-page-header'>
            <img className='header-image' src='/logo.png'/>
            <a className='header-link' href='https://josiesbakery.com/' target='_'>Main Site</a>
        </div>
    );
}

export default FormHeader;