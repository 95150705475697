
import { useState, useEffect } from 'react';

import FormHeader from '../components/FormHeader';
import Spinner from '../components/Spinner';
import { apiPOST } from '../utils/api';

import '../css/LoginPage.css';


function LoginPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [waiting, setWaiting] = useState(false);
    const [authError, setAuthError] = useState(false);


    /**
     * @const handleKeyPress
     * @description Event handler that calls "handleSubmit()" if enter is pressed
     * @param { Event } event 
     * @returns { null }
     */
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(); // Call submit handler
        }
    };

    /**
     * @function handleSubmit
     * @description Submits the login credentials to the API
     * @returns { null }
     */
    async function handleSubmit() { 
        
        // Set waiting before request and clear any previous errors
        setWaiting(true);
        setAuthError(false);

        // Make API req
        const responseJSON = await apiPOST(
            '/login',
            {'credentials': {'username': username, 'password': password}}
        );
        
        // Set waiting to false when response is received
        setWaiting(false);

        // Check the response 
        if(!responseJSON ||responseJSON.status != 200) {
            setAuthError(true);
        } else {
            window.location.href = '/portal';
        }
    }


     // useEffect => add event listeners to the proper elements when the page loads
    useEffect(() => {
        const submitButton = document.getElementById('login-submit-button');
        const usernameInput = document.getElementById('username-input');
        const passwordInput = document.getElementById('password-input');

        // Listen for keyup "Enter" to trigger form submission, already handled by React
        usernameInput.addEventListener('keyup', event => {
            if (event.key === 'Enter') {
                submitButton.click(); // Trigger the button click event
            }
        });

        passwordInput.addEventListener('keyup', event => {
            if (event.key === 'Enter') {
                submitButton.click(); // Trigger the button click event
            }
        });
    }, []);


    return (
        <div className='login-page'>
            <FormHeader />

            <div className='login-page-title'>Portal Login</div>

            <form className='login-page-inner'>
                <input 
                    type='text'
                    className='username-input' 
                    name='username' 
                    id='username-input' 
                    placeholder='username'
                    onChange={(e) => setUsername(e.target.value)}
                />

                <input 
                    type='password'
                    className='password-input' 
                    name='password' 
                    id='password-input' 
                    placeholder='password'
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyPress}
                />

                <div className='error-container'>
                    { authError ? 'Invalid username or password.' : '' }
                </div>

                <button 
                    type='submit'
                    className='login-submit-button'
                    id='login-submit-button'
                    name='login-submit'
                    onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    onKeyDown={handleKeyPress}
                >Login</button>

                <div className='spinner-wrapper'>
                    {   waiting && <Spinner />  }
                </div>
            </form>
            
            
        </div>
    );
}

export default LoginPage;
